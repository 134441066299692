<template>
  <vue-final-modal class="game-limit-reached">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'label') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('game-limit-reached')" />
      </div>
    </div>

    <div class="vfm__body">
      <div class="game-limit-reached__text">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'description') }}
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['gameLimitReached']>;
    defaultLocaleData: Maybe<CIModalsContent['gameLimitReached']>;
  }>();

  const { closeModal } = useModalStore();
  const { getContent } = useProjectMethods();
</script>

<style src="~/assets/styles/components/modal/game-limit-reached.scss" lang="scss" />
